import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Images from '../common/Index';
import * as Api from "../../Apis";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import states from "../common/states"
import LinearProgress from '../common/LinearProgress';
import CurrencyFormat from 'react-currency-format';
import { useMediaQuery } from "@uidotdev/usehooks";
import InfiniteScroll from "react-infinite-scroll-component";
import loader2 from "../../Assets/Images/loader2.svg";

function StoreLogin() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(typeof window !== 'undefined' && window.location.search)
    const location = useLocation()
    let init = {
        store_name: "",
        store_email: "",
        revenue: "",
        industry: "",
        domain: "",
        address: {
            first_name: "",
            last_name: "",
            address: "",
            apartment: "",
            city: "",
            state: "",
            country: "India",
            phone_number: "",
            personal_website_url: "",
            pincode: "",
            is_registered: false
        }
    }
    const storeType = [
        { value: "beauty", label: "Beauty" },
        { value: "clothing", label: "Clothing" },
        { value: "electronics", label: "Electronics" },
        { value: "furniture", label: "Furniture" },
        { value: "handcrafts", label: "Handcrafts" },
        { value: "jewellery", label: "Jewellery" },
        { value: "painting", label: "Painting" },
        { value: "photography", label: "Photography" },
        { value: "restaurants", label: "Restaurants" },
        { value: "groceries", label: "Groceries" },
        { value: "other_food_drink", label: "Other food and drink" },
        { value: "sports", label: "Sports" },
        { value: "toys", label: "Toys" },
        { value: "services", label: "Services" },
        { value: "virtual_services", label: "Virtual services" },
        { value: "other", label: "Other" },
        { value: "do_not_know", label: "I haven’t decided yet" },
    ]

    const [activeTab, setactiveTab] = useState(searchParams.get("type") || "active")
    const tabs = [
        { label: "Active", value: "active" },
        { label: "Expired", value: "expire" },
    ]
    const [value, setValue] = useState(init)
    const [storeList, setStoreList] = useState([])
    const [storeName, setStoreName] = useState("")
    const [showList, setshowList] = useState(false)
    const [loading, setloading] = useState(true)
    const [showForm, setshowForm] = useState(false)
    const [stepForm, setStepForm] = useState(1)
    const [authData, setauthData] = useState({})
    const [nameAvailable, setNameAvailable] = useState(false)
    const [nameData, setNameData] = useState({})
    const [isAccepted, setIsAccepted] = useState(false)
    const [acceptedData, setAcceptedData] = useState({})
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [domain, setDomain] = useState("shopyxa.com")
    const [selectedName, setselectedName] = useState("")
    const [hasNoMoreData, sethasNoMoreData] = useState(false)
    const [showLoader, setshowLoader] = useState(false)
    const [page, setPage] = useState(1)

    const handleOnchange = (e, length) => {
        if (e.target.value.length <= length) {
            if (e.target.name === "store_name") {
                const regex = /^[a-z A-Z 0-9]*$/;
                if (regex.test(e.target.value)) {
                    const result = e.target.value.trim().replace(/\s+/g, '-');
                    console.log("result", result)
                    setStoreName(e.target.value.trim())
                    setselectedName("")
                    setValue({
                        ...value,
                        [e.target.name]: e.target.value.replace(/\s+/g, ' '),
                        // domain: `${e.target.value.trim().split(" ").join("-").toLowerCase()}.${domain}`
                    })
                    if (e.target.value.trim().length > 0) {
                        checkStoreName(e.target.value.trim())
                    }
                }
            } else {
                setValue({ ...value, [e.target.name]: e.target.value });
            }
        }
    }


    const handleAddressChange = (e, length) => {
        if (e.target.value.length <= length) {
            setValue({ ...value, "address": { ...value.address, [e.target.name]: e.target.value } });
        }
    }
    const handleSelectChange = (e) => {
        setValue({ ...value, "address": { ...value.address, [e.target.name]: e.target.value } });
    }
    const handleNumberChange = (val, data) => {
        setValue({ ...value, "address": { ...value.address, 'phone_number': `+${data.dialCode} ${val.replace(/[^0-9]+/g, "").slice(data.dialCode.length)}` } })
    };
    const getStoreByPage = (type, num) => {
        Api.GetApi(`store?page=${num}&type=${type}`).then(res => {
            setshowLoader(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                if (res.res.data?.errorCode == "TOKEN_EXPIRE" || res.res.data?.errorCode == "Cannot read properties of null (reading 'is_superAdmin')") {
                    Api.removeToken()
                }
            } else {
                if (res.data.data) {
                    let arr = []
                    let storeData = []
                    if (storeList.length > 0) {
                        storeData = [...storeList, ...res.data.data]
                    } else {
                        storeData = res.data.data
                    }

                    if (storeData.length) {
                        storeData.map((opt) => {
                            arr.push({ ...opt })
                        })
                        setStoreList(arr)
                        if (res.data.data.length < 20) {
                            sethasNoMoreData(true)
                        } else {
                            sethasNoMoreData(false)
                        }
                    }
                }
            }
        })
    }

    const fetchMoreData = () => {
        console.log("yessssssssss")
        setshowLoader(true)
        setTimeout(() => {
            callFn()
        }, 1000);
    }


    const callFn = useCallback(() => {
        let pageData = page + 1
        getStoreByPage(activeTab, pageData)
        setPage(pageData)
    }, [page, storeList])

    const getStore = (type, param) => {
        Api.GetApi(`store?page=1&type=${param}`).then(res => {
            console.log("res list: ", res)
            setTimeout(() => {
                setloading(false)
            }, 500);
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                if (res.res.data?.errorCode == "TOKEN_EXPIRE" || res.res.data?.errorCode == "Cannot read properties of null (reading 'is_superAdmin')") {
                    Api.removeToken()
                }
            } else {
                if (type == "") {
                    if (res.data.data.length == 0) {
                        Api.GetApi('store?page=1&type=expire').then(res2 => {
                            console.log("res2 list: ", res2)
                            if (res2.error === true) {
                                Api.Alert(res2.response.data.error, "error")
                                if (res2.res.data?.errorCode == "TOKEN_EXPIRE") {
                                    Api.removeToken()
                                }
                            } else {
                                if (res2.data.data.length == 0) {
                                    setshowList(false)
                                    setIsAccepted(false)
                                } else {
                                    setshowList(true)
                                }
                            }
                        })
                    } else {
                        setshowList(true)
                    }
                }
                if (type == "create") {
                    if (res.data.data.length == 1) {
                        let resData = res.data.data[0]
                        Api.PostApi("store/storelogin", { "store_id": resData?.storeId }).then(res => {
                            console.log("res store login: ", res)
                            if (res.error === true) {
                                Api.Alert(res.response.data.error, "error")
                            } else {
                                if (res.data.data.token) {
                                    let url = ""
                                    let domain2 = ""
                                    if (resData?.liveDomain) {
                                        domain2 = resData?.liveDomain
                                    } else {
                                        domain2 = `https://${resData?.domain}`
                                    }
                                    if (Api.buildtype == "local") {
                                        url = `http://${resData.store_name.split(" ").join("-")}.localhost:3001/admin?token=${res.data.data.token}&id=${resData?.store[0]?.id}&role=${resData?.store[0]?.role}`
                                    } else {
                                        url = `https://${resData?.domain}/admin?token=${res.data.data.token}&id=${resData?.store[0]?.id}&role=${resData?.store[0]?.role}`
                                    }
                                    if (resData?.store[0]?.role == 3 && !resData?.store[0]?.is_accepted) {
                                        setIsAccepted(true)
                                        setAcceptedData({ storeId: resData?.storeId, url: url })
                                        setshowList(false)
                                        setshowForm(false)
                                        // let a = document.createElement('a');
                                        // a.href = url;
                                        // if (window.confirm('Accept account invite. Click Ok')) {
                                        //     Api.PostApi('staff/accept', { store_id: resData.storeId }).then(resp => {
                                        //         console.log("res accept list: ", resp)
                                        //         if (resp.error === true) {
                                        //             Api.Alert(resp.response.data.error, "error")
                                        //             if (resp.res.data.errorCode == "TOKEN_EXPIRE") {
                                        //                 Api.removeToken()
                                        //             }
                                        //         } else {
                                        //             a.click();
                                        //         }
                                        //     })
                                        // };
                                    } else {
                                        window.open(url, '_self')
                                    }
                                }
                            }
                        })
                    } else {
                        setStoreList(res.data.data)
                        if (res.data.data.length > 0) {
                            setshowList(true)
                            setshowForm(true)
                        } else {
                            setshowList(false)
                            setshowForm(false)
                        }
                    }
                } else {
                    setStoreList(res.data.data)
                    setshowList(true)
                    // if (type !== "tabChange") {
                    //     if (res.data.data.length > 0) {
                    //         setshowList(true)
                    //         setshowForm(true)
                    //     } else {
                    //         setshowList(false)
                    //         setshowForm(false)
                    //     }
                    // }
                }
            }
        })
    }

    const handleTab = (data) => {
        setactiveTab(data?.value)
        getStore("tabChange", data?.value)
        searchParams.set("type", data?.value)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
        setPage(1)
        sethasNoMoreData(false)
    }

    const checkStoreName = (name) => {
        Api.PostApi("store/name", { "store_name": name }).then(res => {
            console.log("res store name: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data) {
                    setNameAvailable(false)
                } else {
                    setNameAvailable(true)
                }
                setNameData(res.data.data)
            }
        })
    }


    const acceptAccount = (data) => {
        Api.PostApi('staff/accept', { store_id: data?.storeId }).then(res => {
            console.log("res accept list: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
                if (res.res.data.errorCode == "TOKEN_EXPIRE") {
                    Api.removeToken()
                }
            } else {
                window.open(data?.url, '_self')
                setIsAccepted(false)
            }
        })
    }

    console.log("acceptedData: ", acceptedData)

    const acceptRequest = () => {
        acceptAccount(acceptedData)
    }
    const declineRequest = () => {
        setshowList(true)
        setIsAccepted(false)
    }

    const selectStore = (data) => {
        Api.PostApi("store/storelogin", { "store_id": data.storeId }).then(res => {
            console.log("res store login: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (res.data.data.token) {
                    let url = ""
                    let domain2 = ""
                    if (data?.liveDomain) {
                        domain2 = data?.liveDomain
                    } else {
                        domain2 = `https://${data?.domain}`
                    }
                    if (searchParams.get("type") && searchParams.get("type") == "expire") {
                        if (Api.buildtype == "local") {
                            url = `http://${data.store_name.split(" ").join("-")}.localhost:3001/admin?token=${res.data.data.token}&id=${data?.store[0]?.id}&role=${data?.store[0]?.role}`
                        } else {
                            url = `https://${data?.domain}/admin/inactive-store?token=${res.data.data.token}&id=${data?.store[0]?.id}&role=${data?.store[0]?.role}`
                        }
                    } else {
                        if (Api.buildtype == "local") {
                            url = `http://${data.store_name.split(" ").join("-")}.localhost:3001/admin?token=${res.data.data.token}&id=${data?.store[0]?.id}&role=${data?.store[0]?.role}`
                        } else {
                            url = `https://${data?.domain}/admin?token=${res.data.data.token}&id=${data?.store[0]?.id}&role=${data?.store[0]?.role}`
                        }
                    }
                    if (data?.store[0]?.role == 3 && !data?.store[0]?.is_accepted) {
                        setIsAccepted(true)
                        setAcceptedData({ storeId: data.storeId, url: url })
                        setshowList(false)
                        setshowForm(false)
                        // let a = document.createElement('a');
                        // a.href = url;
                        // if (window.confirm('Accept account invite. Click Ok')) {
                        //     Api.PostApi('staff/accept', { store_id: data.storeId }).then(res => {
                        //         console.log("res accept list: ", res)
                        //         if (res.error === true) {
                        //             Api.Alert(res.response.data.error, "error")
                        //             if (res.res.data.errorCode == "TOKEN_EXPIRE") {
                        //                 Api.removeToken()
                        //             }
                        //         } else {
                        //             a.click();
                        //         }
                        //     })
                        // };
                    } else {
                        window.open(url, '_self')
                    }
                }
            }
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingBtn(true)
        Api.PostApi("store", value).then(res => {
            console.log("res create: ", res)
            setLoadingBtn(false)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                if (localStorage.getItem("auth_data")) {
                    let data = JSON.parse(localStorage.getItem("auth_data"))
                    setauthData(data)
                    setValue({
                        ...init,
                        store_email: data?.email,
                        address: {
                            ...value?.address,
                            first_name: data?.first_name,
                            last_name: data?.last_name,
                            phone_number: data?.phone && !data?.phone.includes("+91") ? `+91 ${data?.phone}` : data?.phone || "",
                        }
                    })
                } else {
                    setValue(init)
                }
                Api.Alert(res.data.data?.message, "success")
                getStore("create", "active")
                setStepForm(1)
                setshowList(true)
            }
        })
    }
    useEffect(() => {
        getStore("", searchParams.get("type") || "active")
        if (localStorage.getItem("auth_data")) {
            let data = JSON.parse(localStorage.getItem("auth_data"))
            setauthData(data)
            setValue({
                ...value,
                store_email: data?.email,
                address: {
                    ...value?.address,
                    first_name: data?.first_name,
                    last_name: data?.last_name,
                    phone_number: data?.phone && !data?.phone.includes("+91") ? `+91 ${data?.phone}` : data?.phone || "",
                }
            })
        }
    }, [])

    useEffect(() => {
        // if (process.env.REACT_APP_MODE == "dev") {
        //     setDomain("forgetaday.com")
        // } else {
        //     setDomain("shopyxa.com")
        // }
        setDomain(Api.appURL)
    })

    const btnNext = (e) => {
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

        e.preventDefault()
        if (!pattern.test(value.store_email)) {
            Api.Alert("Please enter valid email", "error")
        } else {
            setStepForm(stepForm + 1)
        }
    }

    const backToStep1 = () => {
        if (storeList.length > 0) {
            setshowForm(false)
            setshowList(true)
        } else {
            setshowForm(false)
            setshowList(false)
        }
    }
    const createNew = () => {
        setshowForm(true)
        setshowList(false)
    }

    const chooseName = (name) => {
        // setselectedName(name)
        setNameAvailable(true)
        setValue({ ...value, store_name: name })
        // setValue({ ...value, domain: `${name}.${domain}` })
    }

    const btnPrevious = (e) => {
        e.preventDefault()
        setStepForm(stepForm - 1)

    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (value?.store_name !== "") {
                setValue({
                    ...value,
                    domain: `${value?.store_name.trim().split(" ").join("-").toLowerCase()}.${domain}`
                })
            }
        }, 1000);

        return () => clearTimeout(delayDebounceFn);
    }, [value?.store_name])

    useEffect(() => {
        if (showForm && storeList.length > 0) {
            let store_val = storeList[0]?.StoreAddress
            setValue({
                ...value,
                address: {
                    ...value?.address,
                    address: store_val?.address || "",
                    apartment: store_val?.apartment || "",
                    city: store_val?.city || "",
                    country: store_val?.country || "India",
                    personal_website_url: store_val?.personal_website_url || "",
                    pincode: store_val?.pincode || "",
                    state: store_val?.state || "",
                }
            })
        }
    }, [showForm, storeList])


    console.log("storeList", storeList)
    console.log("showList", showList)
    console.log("showForm", showForm)
    console.log("value", value)
    return (
        <div className='loginContainer'>
            {loading ?
                <LinearProgress />
                :
                <div className='cardBox cardBox__login cardBoxStore'>
                    <div className='cardBoxStore__header'>
                        <div className='cardBoxStore__container'>
                            <div className='loginLogo'>
                                <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                            </div>
                        </div>
                        {showList ? null :
                            <ul className='stepIndicatior'>
                                <li className={`${stepForm >= "1" ? `active` : ""}`}><span></span></li>
                                <li className={`${stepForm >= "2" ? `active` : ""}`}><span></span></li>
                                <li className={`${stepForm >= "3" ? `active` : ""}`}><span></span></li>
                            </ul>
                        }
                    </div>

                    {isAccepted ?
                        <div className='store-login'>
                            <div className="stepForm step1 ">
                                <div className='cardBoxStore__body'>
                                    <div className='cardBoxStore__container'>
                                        <div className='cardBoxStore__bodyHeading'>
                                            <h4>You have recieved a request to access store</h4>
                                        </div>
                                        <div className='cardBoxStore__footer d-flex align-items-center justify-content-between'>
                                            <div className='cardBoxStore__container'>
                                                <div className='stepFormButtonHolder'>
                                                    <button className='btn-text btn-text-danger inline_logout' onClick={declineRequest}>Decline</button>
                                                    <button className='btn btn-primary' onClick={acceptRequest}>Accept</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            {showList &&
                                <div className='cardBoxStore__body'>
                                    <>
                                        <div className='cardBoxStore__container'>
                                            <div className='cardBoxStore__bodyHeading'>
                                                <h4>Welcome to Shopyxa</h4>
                                                <p className="mb-0">Please see your Shopyxa store with us </p>
                                            </div>
                                            <div className='status_tab_panel'>
                                                {tabs.map((tab, i) => {
                                                    return (
                                                        <button key={i} className={`btn-text btn-text ${tab.value == activeTab ? "active" : ""}`} onClick={() => handleTab(tab)}>{tab.label}</button>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div className='cardBoxStore__bodyScroll' id="scrollableDiv">
                                            <InfiniteScroll
                                                dataLength={storeList.length}
                                                next={fetchMoreData}
                                                hasMore={hasNoMoreData ? false : true}
                                                loader={
                                                    showLoader &&
                                                    <div className="text-center pagination_loader">
                                                        <img src={loader2} alt="" width="50" />
                                                    </div>
                                                }
                                                endMessage={""}
                                                scrollableTarget="scrollableDiv"
                                            >
                                                <div className='cardBoxStore__container'>
                                                    {storeList.length > 0 ?
                                                        <>
                                                            {storeList.map((data) => {
                                                                return (
                                                                    <div key={data.id} className={`mb-3 ${activeTab == "expire" ? "expired" : ""}`}>
                                                                        <button onClick={() => selectStore(data)} className='btn btn-outline-dark w-100 previousStoreBtn  '>
                                                                            <div className='previousStoreBtn__contain'>
                                                                                <h5 className='previousStoreBtn__heading mb-1'>{data.store_name}</h5>
                                                                                <div className='previousStoreBtn__content mb-0'>{data.store_email}</div>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                        :
                                                        <div className='py-5 text-center'>
                                                            <h5>There is no {activeTab == "expire" ? "expired" : "active"} store found</h5>
                                                        </div>
                                                    }
                                                </div>
                                            </InfiniteScroll>
                                        </div>
                                    </>
                                    {/* {!showForm && !showList &&
                    <div className='cardBoxStore__container'>
                        <div className='cardBoxStore__bodyHeading'>
                            <h4>Launch your first online store</h4>
                            <p>Explore our theme collections and pick the best one for your store.</p>
                            <div className='d-flex justify-content-between'>
                            <button className='btn btn-primary px-4 mt-3' onClick={() => setshowForm(true)}>Create store</button>
                            <button className='btn btn-danger px-4 mt-3 ms-3' onClick={() => Api.removeToken()}>Logout</button>
                            </div>
                        </div>
                        </div>
                    } */}
                                </div>
                            }
                            {!showList && !isAccepted &&
                                <div className='store-login'>
                                    {stepForm === 1 ?
                                        <div className="stepForm step1 ">
                                            <div className='cardBoxStore__body'>
                                                <div className='cardBoxStore__container'>
                                                    <div className='cardBoxStore__bodyHeading'>
                                                        <h4>What would you like to name your store?</h4>
                                                    </div>
                                                    <div className='row text-start'>
                                                        <div className='col-sm-12 form-group mb-3'>
                                                            <div className={`form-control d-flex required ${value?.store_name == "" || nameAvailable ? "" : "error"} ${value?.store_name == "" ? "" : "filled"}`}>
                                                                <input type="text" value={value.store_name} name="store_name" placeholder='Store name*' className=' w-100' onChange={(e) => handleOnchange(e, 35)} />
                                                                {/* {value.store_name !== "" &&
                                                    <p className='mt-1 mb-0' style={{ whiteSpace: "nowrap", color: nameAvailable ? "#62a965" : "#ff0000" }}><small>{nameAvailable ? "Available" : "Already taken"}</small></p>
                                                } */}
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 form-group mb-3'>
                                                            <div>
                                                                <input type="email" value={value.store_email} name="store_email" placeholder='Store contact email*' className={`form-control w-100 required ${value?.store_email == "" ? "" : "filled"}`} onChange={(e) => handleOnchange(e, 50)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="namongStoreBox pt-1">
                                                        <div className='flex_input'>
                                                            <div className='font-18 mb-0' style={{ wordBreak: "break-all" }}>
                                                                {value.domain == "" ? `xxx.${domain}` : value.domain}
                                                                {/* {storeName == "" ? "xxx." : `${(selectedName ? selectedName : storeName).split(" ").join("-").toLowerCase()}.`}{domain} */}
                                                            </div>
                                                            {value.store_name !== "" &&
                                                                <p className='mb-0' style={{ whiteSpace: "nowrap", color: nameAvailable ? "#62a965" : "#ff0000" }}>{nameAvailable ? "Available" : "Already taken"}</p>
                                                            }
                                                        </div>
                                                        {!nameAvailable && nameData.suggestList && nameData.suggestList.length > 0 &&
                                                            <div className='suggestion_block'>
                                                                <p className='mb-0'>Available Options:</p>
                                                                <ul className='suggestion_list'>
                                                                    {nameData.suggestList.map((opt, i) => {
                                                                        return (
                                                                            <li key={i}>
                                                                                <button onClick={() => chooseName(opt)}>{opt.toLowerCase()}</button>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        }
                                                        <p className='font-12 text-greytint2 mt-2 mb-0'>Using this temporary URL, you can set up your Shopyxa store. After finishing your store setup, you can either continue with the same domain name or choose a custom domain.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cardBoxStore__footer d-flex align-items-center justify-content-between'>
                                                <div className='cardBoxStore__container'>
                                                    <div className='stepFormButtonHolder'>
                                                        {storeList.length > 0 ?
                                                            <button className='btn btn-secondary' onClick={backToStep1}>Back</button>
                                                            :
                                                            <button className='btn-text btn-text-danger inline_logout' onClick={() => Api.removeToken()}>Logout</button>
                                                        }
                                                        {nameAvailable && value.store_name.trim() !== "" && value.store_email.trim() !== "" ?
                                                            <button className='btn btn-primary' onClick={(e) => btnNext(e)} >Continue</button>
                                                            :
                                                            <button className='btn btn-primary disabled'>Continue</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                    {stepForm === 2 ?
                                        <div className="stepForm step2 ">
                                            <div className='cardBoxStore__body'>
                                                <div className='cardBoxStore__container'>
                                                    <div className='cardBoxStore__bodyHeading'>
                                                        <h4>Tell us something about your business</h4>
                                                    </div>
                                                    <div className='row text-start'>
                                                        <div className='col-sm-12 form-group mb-3'>
                                                            <div className='form-select-outer'>
                                                                <select name="revenue" className='form-control w-100' onChange={(e) => handleOnchange(e, 1000)}>
                                                                    <option value="" disabled selected>Revenue</option>
                                                                    <option value="Upto ₹5,000 INR">Upto ₹5,000 INR</option>
                                                                    <option value="₹5,000 INR to ₹50,000 INR">₹5,000 INR to ₹50,000 INR</option>
                                                                    <option value="₹50,000 INR to ₹250,000 INR">₹50,000 INR to ₹250,000 INR</option>
                                                                    <option value="₹250,000 INR to ₹1,000,000 INR">₹250,000 INR to ₹1,000,000 INR</option>
                                                                    <option value="₹1,000,000 INR+">₹1,000,000 INR+</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 form-group mb-3'>
                                                            <div className='form-select-outer'>
                                                                <select name="industry" className='form-control w-100' onChange={(e) => handleOnchange(e, 1000)}>
                                                                    <option value="" disabled selected>Industry</option>
                                                                    {storeType.map((opt, i) => {
                                                                        return (
                                                                            <option value={opt?.value} key={i}>{opt?.label}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cardBoxStore__footer d-flex align-items-center justify-content-between'>
                                                <div className='cardBoxStore__container'>
                                                    <div className='stepFormButtonHolder'>
                                                        <button className='btn btn-secondary' onClick={(e) => btnPrevious(e)}>Back</button>
                                                        <button className='btn btn-primary' onClick={(e) => btnNext(e)}>Continue</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                    {stepForm === 3 ?
                                        <div className="stepForm step3 active">
                                            <div className='cardBoxStore__body'>
                                                <div className='cardBoxStore__container'>
                                                    <div className='cardBoxStore__bodyHeading'>
                                                        <h4>Tell us something about yourself</h4>
                                                    </div>
                                                    <div className='row gx-2 text-start'>
                                                        <div className='col-sm-6 form-group mb-3'>
                                                            <div>
                                                                <input type="text" name="first_name" value={value.address.first_name} className={`form-control w-100 required ${value?.address.first_name == "" ? "" : "filled"}`} placeholder="First name*" onChange={(e) => handleAddressChange(e, 40)} />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6 form-group mb-3'>
                                                            <div>
                                                                <input type="text" name="last_name" value={value.address.last_name} className={`form-control w-100 required ${value?.address.last_name == "" ? "" : "filled"}`} placeholder="Last name*" onChange={(e) => handleAddressChange(e, 40)} />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 form-group mb-3'>
                                                            <div>
                                                                <input type="text" name="address" value={value.address.address} className={`form-control w-100 required ${value?.address.address == "" ? "" : "filled"}`} placeholder="Address line 1*" onChange={(e) => handleAddressChange(e, 80)} />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 form-group mb-3'>
                                                            <div>
                                                                <input type="text" name="apartment" value={value.address.apartment} className='form-control w-100' placeholder="Address line 2" onChange={(e) => handleAddressChange(e, 80)} />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 col-lg-6 form-group mb-3'>
                                                            <div>
                                                                <input type="text" name="city" value={value.address.city} className={`form-control w-100 required ${value?.address.city == "" ? "" : "filled"}`} placeholder="City*" onChange={(e) => handleAddressChange(e, 30)} />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 col-lg-6 form-group mb-3'>
                                                            <div className='form-select-outer'>
                                                                {/* <input type="text" name="state" value={value.address.state} className='form-control w-100' placeholder="State*" onChange={(e) => handleAddressChange(e, 30)} /> */}
                                                                <select name="state" value={value.address.state} onChange={handleSelectChange} className={`form-control w-100 required ${value?.address.state == "" ? "" : "filled"}`} placeholder="State*">
                                                                    <option value={""} selected>Select state</option>
                                                                    {states.map((opt) => {
                                                                        return (
                                                                            <option key={opt?.code} value={opt?.name}>{opt?.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 col-lg-6 form-group mb-3'>
                                                            <div>
                                                                {/* <input type="text" name="pincode" value={value.address.pincode} className={`form-control w-100 required ${value?.address.pincode == "" ? "" : "filled"}`} placeholder="Pincode*" onChange={(e) => handleAddressChange(e, 6)} /> */}
                                                                <CurrencyFormat format="######" mask="" name="pincode" value={value.address.pincode} className={`form-control w-100 required ${value?.address.pincode == "" ? "" : "filled"}`} placeholder="Pincode*" onChange={(e) => handleAddressChange(e, 6)} />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 col-lg-6 form-group mb-3'>
                                                            {/* <select name="country" value={value.address.country} className='form-control w-100' placeholder="Country">
                                                    <option value="India">India</option>
                                                </select> */}
                                                            {/* <div className='form-control w-100'>{value.address.country}</div> */}
                                                            <div className='customPhone'>
                                                                <PhoneInput
                                                                    enableSearch={true}
                                                                    disableAreaCodes={false}
                                                                    country={'in'}
                                                                    value={value?.address?.phone_number}
                                                                    // onChange={phone => setPhone({ phone })}
                                                                    inputExtrastate={{ name: 'mobile', required: true }}
                                                                    inputProps={{ maxLength: '17' }}
                                                                    onChange={(e, x) => handleNumberChange(e, x)}
                                                                    isValid={(value, data) => {
                                                                        if (
                                                                            value
                                                                                .replace(/[^0-9]+/g, "")
                                                                                .slice(data && (data.dialCode.length)).length < 10 &&
                                                                            value
                                                                                .replace(/[^0-9]+/g, "")
                                                                                .slice(data && (data.dialCode.length)).length > 0
                                                                        ) {
                                                                            return false;
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }}
                                                                    placeholder=""
                                                                    countryCodeEditable={false}
                                                                    defaultMask=".. ... ...."
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-12 form-group mb-3'>
                                                            <div>
                                                                <input type="text" name="personal_website_url" value={value.address.personal_website_url} className='form-control w-100' placeholder="Personal website url (optional)" onChange={(e) => handleAddressChange(e, 100000)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='cardBoxStore__footer d-flex align-items-center justify-content-between'>
                                                <div className='cardBoxStore__container'>
                                                    <div className='stepFormButtonHolder'>
                                                        <button className='btn btn-secondary' onClick={(e) => btnPrevious(e)}>Back</button>
                                                        {loadingBtn ?
                                                            <button className='btn btn-primary disabled'>Please wait...</button>
                                                            :
                                                            value?.address?.first_name.trim() !== "" && value?.address?.last_name.trim() !== "" && value?.address?.address.trim() !== "" && value?.address?.city.trim() !== "" && value?.address?.state.trim() !== "" && value?.address?.pincode !== "" && value?.address?.pincode.toString().length == 6 ?
                                                                <button className='btn btn-primary' onClick={handleSubmit}>Continue</button>
                                                                :
                                                                <button className='btn btn-primary disabled'>Continue</button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : null}
                                    {/* <div className='text-start'>
                                    <label>
                                        <input type="checkbox" name="is_registered" className='me-2' onChange={handleCheckbox} />
                                        This store is a registered business
                                    </label>
                                    <div className=' mt-4'>
                                        <button className='btn btn-primary'>Create</button>
                                        {storeList.length > 0 &&
                                            <button className='btn ms-3 btn-success' onClick={() => setshowList(true)}>Back</button>
                                        }
                                    </div>
                            </div> */}
                                </div>
                            }

                            {showList ?
                                <div className='cardBoxStore__footer'>
                                    <div className='cardBoxStore__container d-flex align-items-center justify-content-between'>
                                        <div className="">
                                            {/* {storeList.length > 0 && */}
                                            < div className='createStoreBtn text-start'>
                                                Create <button className='btn-text btn-text-primary' onClick={createNew}>New store</button>
                                            </div>
                                            {/* } */}
                                        </div>
                                        <div className='text-end'>
                                            <button className='btn-text btn-text-danger' onClick={() => Api.removeToken()}>Logout</button>
                                        </div>
                                    </div>
                                </div> : null}
                        </React.Fragment>
                    }
                </div>
            }
            {
                loadingBtn &&
                <div className='loading full'>
                    <div className='position-relative'>
                        <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="Loading" width="200" />
                        <div className='loader_outer'>
                            <LinearProgress />
                        </div>
                        <h4 className='mb-0 mt-3 text-center'>Don't close the window or hit refresh! <br />Wait patiently while we are creating your store.</h4>
                    </div>
                </div>
            }
        </div >
    );
}

export default StoreLogin;